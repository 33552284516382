import { Mui, MuiStyles } from "@osu/react-ui";

const StatementBalance = ({balanceType, amount}) => {
  const theme = MuiStyles.useTheme();

  return <Mui.Box sx={{
    borderRadius: '.5rem',
    'backgroundColor': theme?.palette?.action?.focus,
    "marginLeft": '1rem',
    "marginBottom": "1rem",
    '&> div': {
      display: 'flex'
    },
    "width": "18rem",
    "maxWidth": "100%"
  }}>
    <Mui.Typography sx={{ padding: "1rem 0", "textAlign": "center", }}>
       {balanceType + ": " + amount}
    </Mui.Typography>
  </Mui.Box>
}


export default StatementBalance;
