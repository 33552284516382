
import { ROLES } from "../../util/constants";
import { icons, keys } from "./enums";
import  Holds  from "../../Holds/components";
import Assignments from "../../Assignments/components"
import Balances from "../../Balances/components";
import StatementOfAccount from "../../StatementOfAccount/components"
import { ACTIVE_TERMS, AWS_LAMBDA_SERVICE, CONTENT_SERVER_SERVICE } from "../../services/data-sources/enums";
import mappings from "../../services/data-sources";
import ClassesCalendar from "../../Calendar/Classes";
//import SEI from "../../SEI/components";
import BuckeyeLearn from "../../BuckeyeLearn/components";

let sections = [
    {
        Icon: icons[keys.HOLDS],
        key: keys.HOLDS,
        Component: Holds,
        roles: [ROLES.STUDENT],
        unique: [
            'amount',
            'summary',
            'startDate',
        ]
    },
    {
        Icon: icons["ASSIGNMENTS"],
        key: "ASSIGNMENTS",
        Component: Assignments,
        roles: [ROLES.STUDENT],
        unique: [

        ]
    },
    {
        Icon: icons[keys.CLASSES],
        key: keys.CLASSES,
        Component: ClassesCalendar,
        roles: [ROLES.STUDENT]
    },
    {
        Icon: icons[keys.BALANCES],
        key: keys.BALANCES,
        Component: Balances,
        roles: [ROLES.STUDENT],
        unique: [
            'barCodeValue',
            'accounts.balance',
            'accounts.name',
        ]
    },
    {
        key: keys.STATEMENT_OF_ACCOUNT,
        Icon: icons[keys.STATEMENT_OF_ACCOUNT],
        Component: StatementOfAccount,
        roles: [ROLES.STUDENT],
        unique: [
            'balanceDue',
            'currentDue',
            'futureDue',
            'pastDue',
        ]
    },/*
    {
        key: keys.INSTRUCTORS,
        Icon: icons[keys.INSTRUCTORS],
        roles: [ROLES.STUDENT],
        Component: SEI
    },*/
    {
        key: keys.BUCKEYELEARN,
        Icon: icons[keys.BUCKEYELEARN],
        roles: [ROLES.STUDENT],
        Component: BuckeyeLearn
    }
]

export const getUniqueStamp = (key, data) => {
    let stamp = ""
    if (!data) {
        return stamp
    }
    const { unique = [] } = sections.find(({ key: sectionKey }) => sectionKey === key) ?? {}
    unique.forEach(identifier => {
        if (identifier.includes(".")) {
            const split = identifier.split(".")
            const prop = data?.[split[0]]
            if (Array.isArray(prop)) {
                const stringify = prop.map(property => {
                    const field = property?.[split[1]]
                    return field
                }).join("-")
                if (stringify) {
                    stamp += stringify
                }
            }
        } else if (data[identifier]) {
            stamp += data[identifier]
        }
    })

    return stamp
}

sections = sections.map(section => {
    let relatedMappings = Object.assign({}, mappings[CONTENT_SERVER_SERVICE], mappings[AWS_LAMBDA_SERVICE]);
    let relatedData = relatedMappings[section.key];
    if(section.key === keys.ASSIGNMENTS) {
        relatedData = mappings[CONTENT_SERVER_SERVICE]["ENROLLMENTS"]
    }
    if(section.key === keys.CLASSES) {
        relatedData = mappings[CONTENT_SERVER_SERVICE][ACTIVE_TERMS]
    }
    if (relatedData?.CONSTANTS?.get) {
        section.query = relatedData.CONSTANTS.get
    }

    return section
})

export {
    sections
}