import { AFFILIATIONS, ANNOUNCEMENTS, BUCKEYELEARN, CHAT } from "../enums.js"
import announcements from "./announcements.js"
import affiliations from "./affiliations.js"
import buckeyeLearn from "./buckeyelearn.js"
import sendText from "./sendText.js"

const AWS_LAMBDA = {
    [ANNOUNCEMENTS]: announcements,
    [AFFILIATIONS]: affiliations,
    [BUCKEYELEARN]: buckeyeLearn,
    [CHAT]: sendText
}

export default AWS_LAMBDA