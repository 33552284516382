import { Mui, MuiIcons } from "@osu/react-ui";
import { focusStyles } from "../../styles/util";
import "../styles/index.scss"
import useChatbotTheming from "../hooks/useChatbotTheming";
import { OSU_CHATBOT_ID } from ".";
import { forwardRef } from "react";

const Fab = forwardRef(({ anchorEl, onClick, onBlur }, ref) => {
  const { accent } = useChatbotTheming()

  return (
      <Mui.Fab
        ref={ref}
        id={OSU_CHATBOT_ID}
        onBlur={onBlur}
        sx={(theme) => ({
          position: "fixed",
          bottom: theme.spacing(2),
          right: theme.spacing(2),
          visibility: anchorEl ? "hidden" : "visible",
          backgroundColor: accent.main,
          color: accent.text,
          "&:hover": {
            backgroundColor: accent.hover,
            color: theme.palette.getContrastText(accent.hover)
          },
          "&:focus": {
            backgroundColor: accent.focus,
            color: theme.palette.getContrastText(accent.focus),
            ...focusStyles
          },
          "&:active": {
            backgroundColor: accent.active,
            color: theme.palette.getContrastText(accent.active),
          }
        })}
        onClick={(event) => {
          onClick(event.currentTarget);
        }}
        variant="extended"
      >
        <MuiIcons.Chat sx={{ mr: 1 }} />
        Chat
      </Mui.Fab>
  );
})
export default Fab;
