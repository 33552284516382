function transformUser(obj = {}) {
    const { applicationRoles = "", cognitoGroups = [], email, nickname, name, osuid, given_name = '', family_name = '' } = obj
    const formattedUser = {
        applicationRoles: applicationRoles.split(","),
        cognitoGroups,
        email: email,
        name,
        fullName: name,
        osuid: osuid,
        firstName: nickname ?? name,
        initials: `${given_name && given_name.charAt(0)}${family_name && family_name.charAt(0)}`.toUpperCase()
    }

    return formattedUser
}

export { transformUser }