import { setRoles } from "../../authorization/slices";
import { getAffiliations } from "../enums";

const CONSTANTS = {
  get: getAffiliations,
};

const get = `
    ${CONSTANTS.get}
`;

const transformResponse = (response = {}, { dispatch }) => {
  const roles = response?.data?.getAffiliations
  if(roles?.length) {
    dispatch(setRoles({ roles }))
  }
  return response
}


const source = {
  CONSTANTS,
  QUERIES: {
    get,
  },
  transformResponse
};
  

export default source;
