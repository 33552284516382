import { setAuthenticationStatus, setAuthenticationUser } from "../services/authorization/slices";
import { graphqlApi } from "../services/graphql/api";
import { authorizationRoutes } from "../Routes/paths";
import { authorizationApi, authorizationEndpoints } from "../services/authorization/api";
import { STATUSES } from "./constants";

export const CHANNELS = {
  auth: "auth",
  core: "core",
  customAuthHeaders: "customAuthHeaders",
};

export const EVENTS = {
  customOAuthState: "customOAuthState",
  signIn: "signIn",
  signOut: "signOut",
  configured: "configured",
  parsingCallbackUrl: "parsingCallbackUrl",
  customState_failure: "customState_failure",
  signIn_failure: 'signIn_failure',
  cognitoHostedUI_failure: 'cognitoHostedUI_failure',
};

export const handleSignInEvent = ({ data = {}, authentication = {}, store = {}}) => {
  const amplifyUser = data?.payload?.data?.signInUserSession?.idToken?.payload
  const reduxUser = authentication?.user
  const roles = authentication?.roles
  
  const properSignInEstablished = !!amplifyUser?.osuid
  const addUserToRedux = properSignInEstablished && (amplifyUser?.osuid !== reduxUser?.osuid)
  const fetchAffiliations = addUserToRedux || (properSignInEstablished && !roles?.length)
  
  if(addUserToRedux) {
    store.dispatch(setAuthenticationUser(amplifyUser))
    store.dispatch(setAuthenticationStatus(STATUSES.SUCCESS)) 
  }
  if(fetchAffiliations) {
    store.dispatch(graphqlApi.endpoints.getAffiliations.initiate())
  }
}

export const handleSignOutEvent = ({ authentication = {}, store = {}})  => {
  const reduxUser = authentication?.user
  if(reduxUser?.osuid) {
    store.dispatch(authorizationApi.endpoints[authorizationEndpoints.logout].initiate())
  }
  store.dispatch(setAuthenticationStatus(STATUSES.CLEARED))
}

export const handleAmplifyConfigured = ({ authentication = {}, store = {}}) => {
  const reduxUser = authentication?.user
  const checkForUnexpectedSamlError = (window?.location?.href ?? '').includes('error_description')

  if(checkForUnexpectedSamlError) {
    handleSignInFailure({ store })
  } else if(!reduxUser?.osuid) {
    store.dispatch(authorizationApi.endpoints[authorizationEndpoints.login].initiate({
      doNotRedirect: true
    }))
  }
}

export const handleParsingCallBackUrl = ({store = {}}) => {
  store.dispatch(setAuthenticationStatus(STATUSES.IDLE)) 
}

export const handleSignInFailure = ({store = {}}) => {
  store.dispatch(setAuthenticationStatus(STATUSES.COGNITO_FAILURE)) 
}


export const handleOAuthStateRedirect = (data) => {
  const path = data?.data
  if(!path || (typeof path !== 'string') ) {
    return "/"
  }
  const redirectExclusions = Object.values(authorizationRoutes)
  let redirectPath = '/'      
  const splitPaths = path?.split('?')

  const validPath = !!path && path.startsWith('/')
  const notAnExclusion = splitPaths[0] &&  !redirectExclusions.includes(splitPaths[0])
  if(validPath && notAnExclusion) {
    redirectPath = path
  }

  return redirectPath
}