import React from "react";
import { INTENTS } from "../util/constants";
import Bot, { IntentsProvider, ThemeProvider } from "@osu/chatbot";
import { MuiIcons } from "@osu/react-ui";
import useCustomizedInteractions from "../hooks/useCustomizedInteractions";
import useChatbotTheming from "../hooks/useChatbotTheming";
import Markdown from "./Markdown";

export const intents = {
  welcome: INTENTS.WELCOME,
  positive: INTENTS.POSITIVE,
  negative: INTENTS.NEGATIVE,
  help: INTENTS.HELP,
};

export const Icons = {
  positive: MuiIcons.ThumbUpAlt,
  negative: MuiIcons.ThumbDownAlt,
  help: MuiIcons.QuestionMark,
  send: MuiIcons.Send,
};

const DataBot = ({ inputProps }) => {
  const { isLoading, handleInput, messages } = useCustomizedInteractions();
  const { chatbotProps: chatbotTheme } = useChatbotTheming();

  return (
    <ThemeProvider theme={chatbotTheme}>
      <IntentsProvider intents={intents} Icons={Icons}>
        <Bot
          className="buckeye-chat-container dark-mode-enabled"
          loading={isLoading}
          messages={messages}
          handleInput={handleInput}
          ResponseProps={{
            Markdown
          }}
          inputProps={inputProps}
        />
      </IntentsProvider>
    </ThemeProvider>
  );
};

Bot.defaultProps = {
  className: "",
  headingStart: 1,
  component: "div",
};

export default DataBot;
