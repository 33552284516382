import { keys as dbKeys } from "../Dashboard/util/enums";
import { BUCKEYELEARN, INSTRUCTORS } from "../services/data-sources/enums";

const COLORS = {
    red: "red",
    gray: "gray",
    blue: "blue",
    orange: "orange",
    green: "green",
    brown: "brown",
    pink: "pink",
    purple: "purple",
    teal: "teal",
    yellow: "yellow",
    osuGray: "osuGray",
    seafoam: "seafoam",
    offwhite: "offwhite",
}

const { ASSIGNMENTS, HOLDS, SEI, BALANCES, CLASSES, STATEMENT_OF_ACCOUNT, STUD_ORGS, QUICK_LINKS, ANNOUNCEMENTS, NEWS, UP_DATES, POPULAR_LINKS, WELLNESS, GETHELP } = dbKeys

const mappings = {
    [WELLNESS]: {
        anxiety: COLORS.teal,
        depression: COLORS.green,
        pregnancy: COLORS.orange,
        physical_wellness: COLORS.blue,
        social_anxiety: COLORS.red,
        eating: COLORS.yellow,
        sexual_health: COLORS.teal,
        academic: COLORS.green,
        trauma: COLORS.orange,
        alcohol: COLORS.blue,
        marijuana: COLORS.red,
        sleep: COLORS.yellow,
        relationship: COLORS.teal,
        financial_wellness: COLORS.green,
        nutrition: COLORS.orange,
        breakups: COLORS.blue,
        grief: COLORS.red,
        drugs:COLORS.yellow,
    },
    [HOLDS]: COLORS.red,
    [ASSIGNMENTS]: COLORS.teal,
    [SEI]: COLORS.seafoam,
    [STATEMENT_OF_ACCOUNT]: COLORS.purple,
    [CLASSES]: COLORS.yellow,
    [BALANCES]: COLORS.green,
    [STUD_ORGS]: COLORS.seafoam,
    [QUICK_LINKS]: COLORS.blue,
    [POPULAR_LINKS]: COLORS.blue,
    [ANNOUNCEMENTS]: COLORS.pink,
    [UP_DATES]: COLORS.orange,
    [NEWS]: COLORS.gray,
    [GETHELP]: COLORS.brown,
    [INSTRUCTORS]: COLORS.pink,
    [BUCKEYELEARN]:  COLORS.brown
}

export {
    mappings,
    COLORS
}