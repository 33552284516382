import { graphqlBaseQuery } from "../../util/graphql";
import AWS_LAMBDA_SERVICE from "../aws-lambda";
import CONTENT_SERVER from ".";
import { setDashboard } from "../../graphql/slices/dashboard";
import { getActiveTerms, getEnrollments } from "../enums";

const findSchema = (q) => {
  const services = [...Object.values(CONTENT_SERVER), ...Object.values(AWS_LAMBDA_SERVICE)];
  const values = services.find(service => service.CONSTANTS.get === q)
  return values?.QUERIES?.get
}

const createDQ = (queries = []) => {
  let query = `query GetDashboard {`
  
  queries.forEach(q => {
      const schema = findSchema(q)
      if(schema) {
          query += schema
      }
  })
  
  query += `
      }
  `

  return query

}

const updateDashboard = ({ data, dispatch, getState }) => {
  const terms = data?.[getActiveTerms]?.terms ?? []
  if(terms?.length) {
    CONTENT_SERVER.ACTIVE_TERMS.transformResponse({
      data: {
        [getActiveTerms]: {
          terms
        }
      }
    }, {
      dispatch,
      getState
    })
  }
  dispatch(setDashboard({ data }))
  const enrollments = data?.[getEnrollments]?.data ?? []
  if(enrollments?.length) {
    CONTENT_SERVER.ENROLLMENTS.transformResponse({
      data: {
        [getEnrollments]: {
          data: enrollments
        }
      }
    }, {
      dispatch,
      getState
    })
  }
}

/* Dashboard */
export const getDashboardBuildQuery = {
  queryFn: async (parms = {}, { dispatch, getState }) => {

    try {
      const { queries, variables = {} } = parms || {};
      let options = {
        query: createDQ(queries),
        variables,
      };
      const db = await graphqlBaseQuery(options);
      if(!db?.data && db.error) throw db;
      updateDashboard({
        data: db?.data,
        dispatch,
        getState
      })

      return db;
    } catch (err) {
      const error = (err?.error ?? err);
      if(typeof error === "string") console.error(error);
      
      const data = (err?.data ?? err?.error?.data ?? {});
      if(Object.keys(data).length) {
        updateDashboard({
          data,
          dispatch,
          getState
        })
        return {
          data
        }
      }
      return err
    }
  },
};
