import { Auth } from "aws-amplify";
import { AUTH_MODE } from "../api-config"

export function omitReduxProps(props = {}) {
  const clonedObject = Object.assign({}, props);
  delete clonedObject.history;
  delete clonedObject.location;
  delete clonedObject.match;
  delete clonedObject.staticContext;
  delete clonedObject.dispatch;

  return clonedObject;
}

export function isUnauthenticated(error) {
  const status =
    error?.response && error?.response?.status ? error.response.status : null;
  const unauthorizedErrors = [
    "No current user",
    "Request failed with status code 401",
    "The user is not authenticated",
  ];

  return unauthorizedErrors.includes(error) || status === 401;
}

export function getGraphQLAuthMode(user) {
  return user?.osuid ? AUTH_MODE.AMAZON_COGNITO_USER_POOLS : AUTH_MODE.AWS_IAM;
}

const isIosDevice = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)

export const encodeMapUrl = (latitude, longitude) => {
  if (!latitude || !longitude) return;
  let prefix = `https://www.google.com/maps/search/?api=1&query`
  if(window.safari || isIosDevice) {
    prefix = `http://maps.apple.com/?daddr`
  }
  return encodeURI(
    `${prefix}=${latitude},${longitude}`
  );
};

export const sortByStringField = (array = [], field) => {
  const sorted = [...array].sort(function (a, b) {
    var textA = a?.[field]?.toUpperCase();
    var textB = b?.[field]?.toUpperCase();
    
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  return sorted;
};

export const getSessionToken = async () => {
  let session
  try {
    session = await Auth.currentSession()
  } catch (error) {
    console.error(error)
  }
  return session?.idToken?.jwtToken
}

export const alterTitleToId = (title = "", encode = true) => {
  let id = ""
  if(encode) {
    id = title.replaceAll("/", "&")
    id = encodeURI(id)
  } else {
    id = decodeURI(id)
    id = title.replaceAll("&", "/")
  }
  return id
} 

export const LIGHTEN = "lighten";
export const DARKEN = "darken";

export const flipOnDarkMode = (darkMode, type) => {
  if (!darkMode) {
    return type;
  }
  if (type === DARKEN) {
    return LIGHTEN;
  }
  return DARKEN;
};

export const buildResponsiveStyle = (minHeight) => {
  // one quarter length of 4k monitor
  const asymptote = 50;
  const sigma = 540;
  const x = window.innerWidth;
  // rational function of the form y = 100 - 50 * (x/(x+1080)) from 0 to 1000, halves 1/vh every 540 pixels
  const maxHeight = `${Math.floor(100 - asymptote * (x / (x + sigma)))}vh`;
  return {
    overflowY: "auto",
    minHeight,
    maxHeight,
    border: "1rem",
  };
};