import { buckeyelink } from "../util/constants";

export const navOverrideStyles = (theme, spacing) => {
  return {
    " #osu-navbar.bux-resets .bux-container, footer.bux-footer > .bux-container": {
      [theme.breakpoints.up("xl")]: {
        maxWidth: theme.breakpoints.values.xl,
        paddingY: 0,
        paddingLeft: theme.spacing(spacing),
        paddingRight: theme.spacing(spacing),
      },
      margin: 0,
      paddingLeft: theme.spacing(spacing),
      paddingRight: theme.spacing(spacing),
      width: "100%",
    },
    " #osu-navbar.bux-resets .bux-container #osu-navname-block img": {
      maxHeight: "2rem",
    },
    " #osu-navname-block, .bux-osu-nav__osu-logo-img": {
     padding: 0,
   },
    "#osu-navlinks a": {
      fontWeight: 700,
    },
    "#osu-navlinks li:last-of-type": {
      marginRight: 0,
    },
  };
};


export const footerProps = {
  address: buckeyelink.address,
  phone: buckeyelink.phone,
  footerLinks: [
    {
      href: "https://go.osu.edu/privacy",
      label: "Privacy Statement",
    },
    {
      href: "https://go.osu.edu/non-discrimination-notice",
      label: "Non-discrimination Notice",
    }
  ],
  ContactProps: {
    hideEmail: true,
    children: <div className="contactLinkContainer">
      <a className="bux-link contactLink" href="https://help.osu.edu/">help.osu.edu</a>
      <span>
      Tell us what you think:&nbsp;
        <a className="bux-link" href="https://go.osu.edu/buckeyelinkfeedback">go.osu.edu/buckeyelinkfeedback</a>
      </span>
    </div>
  }
}

export const linkOverrides = [
  {
    href: "https://www.osu.edu/map",
    children: "Map",
  },
  {
    href: "https://email.osu.edu/",
    children: "Webmail",
  },
  {
    href: "https://www.osu.edu/search/",
    children: "Search Ohio State",
  },
]