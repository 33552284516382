import { createApi } from "@reduxjs/toolkit/query/react";
import { getSearchResultsBuildQuery, buildQuery } from "./buildQueries";
import { searchOptions } from "./constants";
import { getDashboardBuildQuery } from "../data-sources/content-server/dashboard";
import { ASSIGNMENTS, ANNOUNCEMENTS, ARTICLES, AWS_LAMBDA_SERVICE, BUILDINGS, BUCKEYELINK, CONTENT_BUCKET_SERVICE, CONTENT_SERVER_SERVICE, GRADES, STUDENT_ORGS, WELLNESS, AFFILIATIONS, PERSONS, EMERGENCY_ALERTS, FINAL_GRADES, ENROLLMENTS, INSTRUCTORS, ACTIVE_TERMS, BUCKEYELEARN, CHAT } from "../data-sources/enums";

const DISMISS_OPTIONS = {gqlQueryType: "mutation", queryType: "dismiss", variables: ["id"]};
const SEND_OPTIONS = {gqlQueryType: "mutation", queryType: "send", queryParameters: `$message: String!, $sessionId: String` };

export const graphqlApi = createApi({
  reducerPath: "graphqlApi",
  endpoints: (builder) => ({
    getDashboard: builder.query(getDashboardBuildQuery),
    getAffiliations: builder.query(buildQuery(AWS_LAMBDA_SERVICE, AFFILIATIONS)),
    getGrades: builder.query(buildQuery(CONTENT_SERVER_SERVICE, GRADES)),
    getSearchResults: builder.query(getSearchResultsBuildQuery),
    getStudentOrgs: builder.query(buildQuery(CONTENT_SERVER_SERVICE, STUDENT_ORGS)),
    getBuckeyeLinkData: builder.query(buildQuery(CONTENT_BUCKET_SERVICE, BUCKEYELINK)),
    getArticles: builder.query(buildQuery(CONTENT_SERVER_SERVICE, ARTICLES)),
    getWellness: builder.query(buildQuery(CONTENT_SERVER_SERVICE, WELLNESS)),
    getBuildings: builder.query(buildQuery(CONTENT_SERVER_SERVICE, BUILDINGS)),
    getAnnouncements: builder.query(buildQuery(AWS_LAMBDA_SERVICE, ANNOUNCEMENTS)),
    dismissAnnouncements: builder.mutation(buildQuery(AWS_LAMBDA_SERVICE, ANNOUNCEMENTS, DISMISS_OPTIONS)),
    getAllAnnouncements: builder.query(buildQuery(AWS_LAMBDA_SERVICE, ANNOUNCEMENTS, {queryType: "getAll"} )),
    getPerson: builder.query(buildQuery(CONTENT_SERVER_SERVICE, PERSONS)),
    getEmergencyAlerts: builder.query(buildQuery(CONTENT_SERVER_SERVICE, EMERGENCY_ALERTS)),
    getAssignments: builder.query(buildQuery(CONTENT_SERVER_SERVICE, ASSIGNMENTS)),
    getFinalGrades: builder.query(buildQuery(CONTENT_SERVER_SERVICE, FINAL_GRADES)),
    getEnrollments: builder.query(buildQuery(CONTENT_SERVER_SERVICE, ENROLLMENTS)),
    getActiveTerms: builder.query(buildQuery(CONTENT_SERVER_SERVICE, ACTIVE_TERMS)),
    getInstructors: builder.query(buildQuery(CONTENT_SERVER_SERVICE, INSTRUCTORS)),
    getBuckeyeLearnTranscript: builder.query(buildQuery(AWS_LAMBDA_SERVICE, BUCKEYELEARN)),
    sendTextToLex: builder.mutation(buildQuery(AWS_LAMBDA_SERVICE, CHAT, SEND_OPTIONS))
  }),
});

export const { 
  useLazyGetDashboardQuery, 
  useGetDashboardQuery, 
  useLazyGetSearchResultsQuery, 
  useGetSearchResultsQuery,
  useGetStudentOrgsQuery,
  useGetBuckeyeLinkDataQuery,
  useGetArticlesQuery,
  useGetAffiliationsQuery,
  useLazyGetArticlesQuery,
  useGetWellnessQuery,
  useLazyGetWellnessQuery,
  useGetBuildingsQuery,
  useGetAnnouncementsQuery,
  useLazyGetAnnouncementsQuery,
  useDismissAnnouncementsMutation,
  useGetAllAnnouncementsQuery,
  useLazyGetAllAnnouncementsQuery,
  useGetPersonQuery,
  useGetEmergencyAlertsQuery,
  useGetGradesQuery,
  useGetAssignmentsQuery,
  useGetFinalGradesQuery,
  useLazyGetFinalGradesQuery,
  useGetEnrollmentsQuery,
  useGetActiveTermsQuery,
  useGetInstructorsQuery,
  useGetBuckeyeLearnTranscriptQuery,
  useSendTextToLexMutation
} =
  graphqlApi;

  export const searchQueryMapping = {
    [searchOptions.getGoogle]: useGetDashboardQuery
}
